/* You can add global styles to this file, and also import other style files */


/* Provide sufficient contrast against white background */

* {
    margin: 0px;
}
*:focus {
    outline: none;
}

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

@font-face {
    font-family: 'parisienneregular';
    src: url('assets/fonts/parisienne-regular-webfont.woff2') format('woff2'), url('assets/fonts/parisienne-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueScript';
    src: url('assets/fonts/LeagueScript.eot?#iefix') format('embedded-opentype'), url('assets/fonts/LeagueScript.woff') format('woff'), url('assets/fonts/LeagueScript.ttf') format('truetype'), url('assets/fonts/LeagueScript.svg#LeagueScript') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Allura-Regular';
    src: url('assets/fonts/Allura-Regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/Allura-Regular.woff') format('woff'), url('assets/fonts/Allura-Regular.ttf') format('truetype'), url('assets/fonts/Allura-Regular.svg#Allura-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}